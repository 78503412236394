<template>
  <OrganizationDetailItemEditCard confirmation-message="Organisatienaam werd succesvol gewijzigd"
    title="Bewerk organisatienaam" update-store-action="organizationModule/updateOrganizationName"
    :update-store-action-payload="{ organizationName: organizationName, organizationId: organizationId }"
    :organizationId="organizationId" :promises.sync="promises">
    <template v-slot:form-fields>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field v-model="organizationName" persistent-placeholder label="Titel" type="text"
            :rules="organizationNameRules" required />
        </v-col>
      </v-row>
    </template>
  </OrganizationDetailItemEditCard>
</template>

<script>
import OrganizationDetailItemEditCard from './OrganizationDetailItemEditCard.vue'

export default {
  name: "OrganizationDetailsEditOrganizationName",
  props: {
    organizationId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      organizationName: null,
      promises: [
        this.$store.dispatch('organizationModule/fetchOrganizationName', this.organizationId)
          .then((result) => {
            this.organizationName = result.name
          })
      ],
      organizationNameRules: [
        v => !!v || 'Organisatie naam moet ingevuld worden',
      ],
    }
  },
  components: {
    OrganizationDetailItemEditCard
  }
}
</script>