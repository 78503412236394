<template>
  <DetailItemEditCard v-bind="$attrs" v-on="$listeners" :item-name="organizationName"
    :promises.sync="detailItemEditCardPromises" :formPromises.sync="computedPromises"
    :detailViewRouteLocation="{ name: routeNames.ORGANIZATION_DETAIL, params: { organizationId: organizationId } }">
    <template v-slot:form-fields>
      <slot name="form-fields" />
    </template>
    <template v-slot:buttons-left="slotProps">
      <slot name="buttons-left" v-bind="slotProps"/>
    </template>
    <template v-slot:save-button-content>
      <slot name="save-button-content" />
    </template>
  </DetailItemEditCard>
</template>
  
<script>
import DetailItemEditCard from "@/components/shared/DetailItemEditCard.vue"
import routeNames from "@/router/RouteNames";

export default {
  name: "OrganizationDetailItemEditCard",
  props: {
    organizationId: {
      type: String,
      required: true
    },
    promises: {
      default: () => []
    }
  },
  emits: ["update:promises"],

  data() {
    return {
      organizationName: null,
      routeNames,
      detailItemEditCardPromises: [this.$store.dispatch('organizationModule/fetchOrganizationName', this.organizationId)
        .then((result) => this.organizationName = result.name)]
    }
  },

  inheritAttrs: false,
  components: {
    DetailItemEditCard,
  },
  computed: {
    computedPromises: {
      get() {
        return this.promises
      },
      set(newValue) {
        this.$emit("update:promises", newValue)
      }
    }
  },
}
</script>